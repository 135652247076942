<template>
  <div>
    <hedaer @setSelect="setItem"></hedaer>
    <div class="aboutUs item" :style="{ 'background-color': selectBab == 4 ? '#f9fafd' : '#FFFFFF' }">
      <ul class="home-tab wow fadeInUp" data-wow-delay="0.3s" style="padding-left: 0; padding-right: 0">
        <li :class="{ selectBab: selectBab == 1 }" @click="selectBab = 1">
          品牌介绍
        </li>
        <li :class="{ selectBab: selectBab == 2 }" @click="selectBab = 2">
          里程碑事件
        </li>
        <li :class="{ selectBab: selectBab == 3 }" @click="selectBab = 3">
          企业文化
        </li>
        <li :class="{ selectBab: selectBab == 4 }" @click="selectBab = 4">
          荣誉奖项
        </li>
      </ul>
      <div class="home-line wow fadeInUp" data-wow-delay="0.3s"></div>
      <div v-show="selectBab == 1" class="BrandIntroduction">
        <div class="Profile-title wow fadeInUp" data-wow-delay="0.4s">
          品牌介绍
        </div>
        <div class="Profile-en wow fadeInUp" data-wow-delay="0.5s">
          BRAND INTRODUCTION
        </div>
        <div class="Profile-text wow fadeInUp" data-wow-delay="0.6s">
          碧莲盛成立于2005年，是一家以植发技术的自主研发和临床应用为核心，致力于毛发健康产业发展的全国直营连锁机构。18年来精钻植发技术，连续13年参加世界植发大会，向世界展示中国植发技术。
        </div>
        <div class="Profile-text wow fadeInUp">
          在华盖资本5亿融资加持下，碧莲盛全力推动品牌战略升级，通过成立技术研发中心、重塑服务体系、扩展旗舰医院、研发自有品牌护发产品等，不断深化行业布局，加强上下游资源整合，线上线下链路打通，构建一个集养发、护发、健发、植发于一体的毛发健康产业生态闭环。
        </div>
        <div class="Profile-text wow fadeInUp">
          目前，碧莲盛在全国开设了40余家直营机构，拥有120余位执业医生，700余位执业护士，其中，主任/副主任医师11位，为35万发友解决了脱发困扰。
        </div>
        <img src="../assets/aboutUs/1.jpg" alt="" class="BrandIntroduction-img wow fadeInUp" />
      </div>
      <div v-show="selectBab == 2">
        <div class="Profile-title wow fadeInUp">里程碑事件</div>
        <div class="Profile-en wow fadeInUp">MILESTONE EVENTS</div>
        <div class="MilestoneEvents">
          <div class="MilestoneEvents-left"></div>
          <div class="MilestoneEvents-right">
            <div v-for="(item, index) in MilestoneEvents" :key="index" class="wow fadeInUp" :data-wow-delay="`0.${index}s`">
              <div class="MilestoneEvents-title">
                {{ item.title }}
              </div>
              <p class="MilestoneEvents-text" v-for="(i, inde) in item.text" :key="inde">
                {{ i }}
              </p>

              <img :src="item.img" alt="" class="MilestoneEvents-img" />
              <div class="MilestoneEvents-line" v-if="index !== 11"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="selectBab == 3">
        <div class="Profile-title wow fadeInUp">企业文化</div>
        <div class="Profile-en wow fadeInUp">CORPORATE CULTURE</div>
        <div class="corporateCulture">
          <div style="display: flex; align-items: flex-end">
            <div class="Culture-title wow fadeInUp">品牌愿景</div>
          </div>
          <div class="Profile-text wow fadeInUp Profile-font" style="margin-top:0.2rem">
            成为中国毛发健康服务创领者
          </div>
          <img src="https://static.drlianzhuren.com/brandWeb//home/yj.jpg" alt="" class="Culture-img wow fadeInUp" />
        </div>
        <div class="corporateCulture">
          <div style="display: flex; align-items: flex-end">
            <div class="Culture-title wow fadeInUp">品牌使命</div>
          </div>
          <div class="Profile-text wow fadeInUp Profile-font" style="margin-top:0.2rem">
            医者仁心、医疗为本，为发友的健康美好生活提供终生服务
          </div>
          <img src="https://static.drlianzhuren.com/brandWeb//home/sm.jpg" alt="" class="Culture-img wow fadeInUp" />
        </div>
        <div class="corporateCulture">
          <div class="Culture-title wow fadeInUp" style="margin-bottom: 0.5rem">
            品牌价值观
          </div>
          <div class="BrandValues">
            <div class="Culture-animation" v-if="BrandValues == 1">
              <div :class="[
                  BrandValues > beforeBrandValues
                    ? 'Culture-flxed'
                    : 'Culture-ani',
                ]"></div>
              <img src="https://static.drlianzhuren.com/brandWeb/home/corporate6.jpg" alt="" class="Culture-img" style="margin-top: 0.1667rem" />
              <div class="BrandValues-select">
                <div class="BrandValues-title">客户第一</div>
              </div>
            </div>
            <div class="BrandValues-item" @click="
                beforeBrandValues = BrandValues;
                BrandValues =1;
              " v-if="BrandValues !== 1">
              客户第一
            </div>
            <div v-if="BrandValues == 2" class="Culture-animation">
              <div :class="[
                  BrandValues > beforeBrandValues
                    ? 'Culture-flxed'
                    : 'Culture-ani',
                ]"></div>
              <img src="https://static.drlianzhuren.com/brandWeb/home/corporate1.jpg" alt="" class="Culture-img" style="margin-top: 0.1667rem" />
              <div class="BrandValues-select">
                <div class="BrandValues-title">至诚守信</div>
              </div>
            </div>
            <div class="BrandValues-item" @click="
                beforeBrandValues = BrandValues;
                BrandValues = 2;
              " v-if="BrandValues !== 2">
              至诚守信
            </div>
            <div class="Culture-animation" v-if="BrandValues == 3">
              <div :class="[
                  BrandValues > beforeBrandValues
                    ? 'Culture-flxed'
                    : 'Culture-ani',
                ]"></div>
              <img src="https://static.drlianzhuren.com/brandWeb/home/corporate2.jpg" alt="" class="Culture-img" style="margin-top: 0.1667rem" />
              <div class="BrandValues-select">
                <div class="BrandValues-title">务实进取</div>
              </div>
            </div>
            <div class="BrandValues-item" @click="
                beforeBrandValues = BrandValues;
                BrandValues = 3;
              " v-if="BrandValues !== 3">
              务实进取
            </div>
            <div class="Culture-animation" v-if="BrandValues == 4">
              <div :class="[
                  BrandValues > beforeBrandValues
                    ? 'Culture-flxed'
                    : 'Culture-ani',
                ]"></div>
              <img src="https://static.drlianzhuren.com/brandWeb/home/corporate4.jpg" alt="" class="Culture-img" style="margin-top: 0.1667rem" />
              <div class="BrandValues-select">
                <div class="BrandValues-title">责任担当</div>
              </div>
            </div>
            <div class="BrandValues-item" @click="
                beforeBrandValues = BrandValues;
                BrandValues = 4;
              " v-if="BrandValues !== 4">
              责任担当
            </div>
            <div class="Culture-animation" v-if="BrandValues ==5">
              <div :class="[
                  BrandValues > beforeBrandValues
                    ? 'Culture-flxed'
                    : 'Culture-ani',
                ]"></div>
              <img src="https://static.drlianzhuren.com/brandWeb/home/corporate4.jpg" alt="" class="Culture-img" style="margin-top: 0.1667rem" />
              <div class="BrandValues-select">
                <div class="BrandValues-title">创新求变</div>
              </div>
            </div>
            <div class="BrandValues-item" @click="
                beforeBrandValues = BrandValues;
                BrandValues = 5;
              " v-if="BrandValues !== 5">
              创新求变
            </div>
            <div class="Culture-animation" v-if="BrandValues == 6">
              <div :class="[
                  BrandValues > beforeBrandValues
                    ? 'Culture-flxed'
                    : 'Culture-ani',
                ]"></div>
              <img src="https://static.drlianzhuren.com/brandWeb/home/corporate5.jpg" alt="" class="Culture-img" style="margin-top: 0.1667rem" />
              <div class="BrandValues-select">
                <div class="BrandValues-title">高效执行</div>
              </div>
            </div>
            <div class="BrandValues-item" @click="
                beforeBrandValues = BrandValues;
                BrandValues = 6;
              " v-if="BrandValues !== 6">
              高效执行
            </div>

          </div>
        </div>
      </div>
      <div v-show="selectBab == 4">
        <div class="Profile-title wow fadeInUp">荣誉奖项</div>
        <div class="Profile-en wow fadeInUp">HONORARY AWARDS</div>
        <div class="awards-img">
          <div>
            <img :src="`${$url}/fbs.jpg`" t="" class="fadeInUpBig wow" />

            <div class="poab">
              <div>
                <div>福布斯中国</div>
                <div>植发行业杰出品牌</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/hy.jpg`" t="" class="fadeInUpBig wow" data-wow-delay="0.1s" />
            <div class="poab">
              <div>
                <div>中国(行业)十大领军品牌</div>
                <div>亚洲品牌十大创新人物</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/home/awards1.jpg`" t="" class="fadeInUpBig wow" />

            <div class="poab">
              <div>
                <div>中国创新医疗</div>
                <div>服务榜单100强</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/home/awards2.jpg`" t="" class="fadeInUpBig wow" data-wow-delay="0.1s" />
            <div class="poab">
              <div>
                <div>最青睐的</div>
                <div>高端植发品牌</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/home/awards3.jpg`" t="" class="fadeInUpBig wow" data-wow-delay="0.1s" />
            <div class="poab">
              <div>
                <div>植发行业领军企业</div>
                <div>医美行业科技创新奖</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/home/awards4.jpg`" t="" class="fadeInUpBig wow" data-wow-delay="0.2s" />
            <div class="poab">
              <div>
                <div>中国经济</div>
                <div>十大杰出女性</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/home/awards5.jpg`" t="" class="fadeInUpBig wow" data-wow-delay="0.3s" />
            <div class="poab">
              <div>
                <div>21世纪</div>
                <div>全国创新公司50强</div>
              </div>
            </div>
          </div>
          <div>
            <img :src="`${$url}/home/awards6.jpg`" t="" class="fadeInUpBig wow" data-wow-delay="0.3s" />
            <div class="poab">
              <div>
                <div>突破性</div>
                <div>技术创新案例</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer @setItem="setItem"></Footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectBab: 1,
      beforeBrandValues: 0,
      BrandValues: 1,
      MilestoneEvents: [
        {
          title: '2022年',
          text: [
            '发起了六一爱心助学公益行活动，为山西河津市上市小学捐赠爱心助学物质。',
            '荣登第五届未来医疗100强大会中国创新医疗服务榜100强，企业估值45亿。'
          ],
          img: `${this.$url}/home/milepost/2022.jpg`
        },
        {
          title: '2021年',
          text: [
            '参加第五届中国毛发移植大会，共同起草《毛发移植规范》团体标准。',
            '成立碧莲盛名医堂，18位在碧莲盛执业7年以上、主治/主任资质的医生入围首批名单。'
          ],
          img: `${this.$url}/home/milepost/2021.jpg`
        },
        {
          title: '2020年',
          text: [
            '武汉新冠疫情爆发后，成立100万专项基金，采购医疗专用防疫物质，并运往疫情前线。',
            '在深圳举办发布会，正式推出不剃发植发技术，将世界植发技术标准推向一个全新的高度。'
          ],
          img: `${this.$url}/home/milepost/2020.jpg`
        },
        {
          title: '2019年',
          text: [
            '与中国人保（PICC）达成战略合作，保障发友合法权益。',
            '参加中国植发大会，董事长尤丽娜发表《植发手术行业规范》重要演讲。'
          ],
          img: `${this.$url}/home/milepost/2019.jpg`
        },
        {
          title: '2018年',
          text: [
            '华盖资本投资碧莲盛，两大巨头强强联手，促进植发行业健康发展。',
            '与阿里健康达成战略合作，加入天猫医美“安心美颜”联盟。'
          ],
          img: `${this.$url}/home/milepost/2018.jpg`
        },
        {
          title: '2017年',
          text: ['董事长尤丽娜成为中国首位入驻世界植发大会案例中心的医生。'],
          img: `${this.$url}/home/milepost/2017.jpg`
        },
        {
          title: '2015年',
          text: ['受邀参加央视315晚会。'],
          img: `${this.$url}/home/milepost/2015.jpg`
        },
        {
          title: '2013年',
          text: [
            '受邀参加第四届国际资本峰会，董事长尤丽娜受到法国总统奥朗德接见。',
            '亲赴雅安地震灾区，捐出价值约100万的救灾物资，资助1000多个学生和家庭。'
          ],
          img: `${this.$url}/home/milepost/2013.jpg`
        },
        {
          title: '2012年',
          text: ['自主研发精密即插即种植发技术，再次成为世界植发大会焦点。'],
          img: `${this.$url}/home/milepost/2012.jpg`
        },
        {
          title: '2010年',
          text: [
            '自主研发加密即插即种植发技术，并在当年世界植发大会上公开展示。'
          ],
          img: `${this.$url}/home/milepost/2010.jpg`
        },
        {
          title: '2008年',
          text: ['受邀参加世界植发大会，向世界展示中国植发技术。'],
          img: `${this.$url}/home/milepost/2008.jpg`
        },
        {
          title: '2005年',
          text: [
            '碧莲盛首家植发医院——北京碧莲盛医疗美容门诊部正式成立。',
            '成为世界植发协会会员和欧洲植发协会会员。'
          ],
          img: `${this.$url}/home/milepost/2005.jpg`
        }
      ]
    }
  },
  methods: {
    setItem (a) {
      this.selectBab = a
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.selectBab = this.$route.params.id + 1
    }
  }
}
</script>

  <style  lang="scss">
.BrandIntroduction-img {
  width: 500px;
  height: 325px;
  margin-top: 30px;
  border-radius: 8px;
}
.MilestoneEvents {
  display: flex;
  .MilestoneEvents-left {
    width: 1.5px;
    background-color: #b8b8b8;
    height: 6300px;
    margin-right: 20px;
  }
  .MilestoneEvents-right {
    .MilestoneEvents-title {
      margin-top: 30px;
      font-size: 33px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #363636;
      position: relative;
    }
    .MilestoneEvents-title::after {
      position: absolute;
      left: -30px;
      top: 9px;
      width: 16px;
      height: 16px;
      background-color: #ffffff;
      border: solid 1px #363636;
      content: '';
      display: inline-block;
      border-radius: 50%;
    }
    .MilestoneEvents-title::before {
      position: absolute;
      left: -25px;
      z-index: 2;
      top: 15px;
      width: 7px;
      height: 7px;
      background-color: #363636;
      content: '';
      display: inline-block;
      border-radius: 50%;
    }
    .MilestoneEvents-text {
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 30px;
      letter-spacing: 0px;
      color: #707070;
    }
    .MilestoneEvents-img {
      width: 470px;
      height: 300px;
      border-radius: 8px;
    }
    .MilestoneEvents-line {
      width: 470px;
      margin-top: 30px;
      height: 1px;
      background: #b8b8b8;
    }
  }
}
.corporateCulture {
  margin-top: 50px;
  .Culture-Subtitle {
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    margin-left: 10px;
    letter-spacing: 0px;
    color: #363636;
  }
  .BrandValues {
    .BrandValues-item {
      width: 500px;
      height: 50px;
      font-size: 20px;
      border: solid 1px #b8b8b8;
      text-align: center;
      color: #707070;
      line-height: 50px;
      margin-top: 8px;
    }
    .BrandValues-select {
      background-color: #2e59a7;
      text-align: center;
      color: #ffffff;
      padding: 25px 0 15px 0;
      .BrandValues-title {
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 12px;
      }
      .BrandValues-text {
        font-size: 19px;
        line-height: 28px;
      }
    }
  }
  .Culture-title {
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #363636;
  }
  .Culture-img {
    width: 500px;
    height: 325px;
    margin-top: 18px;
    vertical-align: middle;
  }
}

@keyframes poabanimate {
  0% {
    background-color: #ffffff;

    opacity: 1;
    border-radius: 50%;
  }

  100% {
    background-color: #2e59a7;

    opacity: 1;
    border-radius: 50%;
  }
}
.awards-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  img:nth-child(4n) {
    margin-right: 0;
  }

  > div {
    width: 243px;
    height: 243px;
    margin-bottom: 30px;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .poab {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      opacity: 0;
      font-size: 10px;
      color: #ffffff;
      text-align: center;
      transform: scale(0);
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        > div {
          line-height: 20px;
        }
      }

      background-color: #2e59a7;
    }
  }

  > div:hover {
    .poab {
      font-size: 13px;
      line-height: 35px;
      transform: scale(1.5);
      animation: poabanimate 1s linear both;
    }
  }
}
.Culture-animation {
  position: relative;
}

.Culture-flxed {
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  height: 450px;
  animation: Culture 1s linear both;
}
.Culture-ani {
  width: 120%;
  overflow: hidden;
  position: absolute;
  bottom: -1px;
  left: -20px;
  background-color: #ffffff;
  animation: reverseCulture 1s linear both;
  height: 440px;
}
@keyframes Culture {
  0% {
    height: 450px;
  }

  100% {
    height: 0px;
  }
}
@keyframes reverseCulture {
  0% {
    height: 460px;
  }

  100% {
    height: 0px;
  }
}

.Profile-font {
  font-size: 22px;
}
</style>
